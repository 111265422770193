<template>
  <section class="section--list-wrap">
    <ul 
      class="section--column"
    >
      <li
        v-for="(item, index) in itemsList" 
        :key="index"
        class="section--column__item"
      >
        <a 
          class="link"
          @click.prevent="clickHandler(item.slug)"
        >
          {{ item.title }}
        </a>
      </li>
    </ul>
  </section>
</template>

<script>
  export default {
    name: 'StoresCategoryListSection',
    props: {
      items: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    computed: {
      itemsList() {
        return  this.items.map(store => {
          return {
            title: store.title, 
            slug: store.slug,
          }
        });
      },
    },
    methods:{
      clickHandler(slug) {
        this.$router.push({ path: `/s/${slug}` });
      },
    },
  }
</script>

<style lang="scss" scoped>
  .section {
    &--list-wrap {
      padding-top: 15px;
    }

    &--column {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0px;
      list-style-type: none;

      &__item {
        margin-bottom: 15px;
        height: 25px;
        overflow: hidden;
        flex-basis: 25%;

        .link {
          color:#2F3B45;
          font-size: 16px;
          font-weight: 400;
          display: block;
          overflow: hidden;
          margin-right: 15px;

          &:hover {
            color: $main-color;
            cursor: pointer;
          }
        }
      }
    }
  }

  @media(max-width: 720px) {
    .section {
      &--column {
        &__item {
          flex-basis: 33.3%;
        }
      }
    }
  }

  @media(max-width: 550px) {
    .section {
      &--column {
        &__item {
          flex-basis: 50%;
        }
      }
    }
  }
</style>