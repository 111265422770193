<template>
  <div class="catalogWrap">
    <div v-for="(store, index) in allStore" :key="index" class="title-link-all">
      <template v-if="store.cats.length && isGridLayout">
        <div class="title-wrap">
          <h2 class="default-title">
            {{ store.label }}
          </h2>
          <router-link class="mr-3" :to="storeLink(store.link)">
            View all
          </router-link>
        </div>
        <div class="carouselWrap">
          <swiper-carousel
            :items="store.cats.slice(0, 10)"
            :items-type="'circle'"
            :config="carouselConfig"
            :link="`/stores/${store.link}`"
          />
        </div>
        <div class="divider"/>
      </template>
      <template v-if="store.cats.length && isListLayout">
        <h2 class="default-title">
          {{ store.label }}
        </h2>

        <stores-category-list-section
          :items="store.cats"
        />

        <div class="divider"/>
      </template>
    </div>
  </div>
</template>
<script>
  import { mapGetters, mapState, mapActions } from 'vuex';
  import { CIRCLE_CAROUSEL_ITEMS_CONFIG } from 'src/config/carousel.config';

  import SwiperCarousel from 'src/components/swiper-carousel/swiper-carousel';
  import StorefrontHeader from './storefrontHeader';
  import StoresCategoryListSection from './storesCategoryListSection';

  export default {
    name: 'Stores',
    components: {
      SwiperCarousel,
      StorefrontHeader,
      StoresCategoryListSection,
    },
    props: {
      items: {
        type: Array,
        require: true,
        default: () => [],
      },
    },
    data() {
      return {
        carouselConfig: {
          ...CIRCLE_CAROUSEL_ITEMS_CONFIG,
          breakpoints: {
            1760: { slidesPerView: 8 },
            1600: { slidesPerView: 7 },
            1440: { slidesPerView: 6 },
            1280: { slidesPerView: 5 },
            1120: { slidesPerView: 5 },
            960: { slidesPerView: 5 },
            800: { slidesPerView: 5 },
            640: { slidesPerView: 4 },
            480: { slidesPerView: 3 },
            300: { slidesPerView: 2 },
          },
        },
      };
    },
    computed: {
      ...mapState({
        showStore: (state) => state.allStoreCategories.showStore,
      }),
      ...mapGetters({
        allStore: 'allStoreCategories/allStore',
        layoutType: 'allStoreCategories/layoutType',
      }),
      storeLink() {
        const filterParams = this.$route.query.filters ? `&filters=${this.$route.query.filters}` : '';
        const sortParams = this.$route.query.sort ? `&sort=${this.$route.query.sort}` : '';
        return store => `${store ? `/stores/${store}?` : '/stores?'}${sortParams}${filterParams}`
      },
      isGridLayout() {
        return this.layoutType === 'grid';
      },
      isListLayout() {
        return this.layoutType === 'list';
      },
    },
    methods:{
      ...mapActions({getAllStore: 'allStoreCategories/getAllStore'}),
    },
  };
</script>
<style lang="scss" scoped>
.catalogWrap {
  padding-top: 50px;
  padding-left: 20px;
  width: 100%;
}

.carouselWrap {
  overflow: hidden;
  padding: 5px 25px;
}
.title-link-all {
  margin-bottom: 30px;

  a {
    font-weight: 600;
    font-size: 14px;
    color: $main-color;
    cursor: pointer;
  }
}

.title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  height: 1px;
  background: $segment-border-color;
}

.default-title {
  font-weight: 600;
  font-size: 22px;
  color: $main-color-items;
}
</style>
