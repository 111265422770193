<template>
  <div class="wrap">
    <vue-headful
      :title="metadata.title"
      :description="metadata.description"
      :image="metadata.image"
      :url="metadata.url"
      :keywords="metadata.keyword"
      :head="metadata.head"
    />

    <b-spinner v-if="loading" class="spinner-border" />
    <template v-else>
      <left-sidebar-menu
        :class="{ 'show-filters': isFiltersShow }"
        @hideFilters="hideFilters"
      />
      <div class="storesWrap py-5">
        <storefront-header @showFilters="showFilters" />
        <stores v-if="showStoresAll" :items="allStore" />
        <all-store-items v-else />
      </div>
    </template>
  </div>
</template>
<script>
  import leftSidebarMenu from '@/components/allStores/leftSidebarMenu.vue';
  import Stores from '@/components/allStores/stores.vue';
  import allStoreItems from '@/components/allStores/allStoreItems.vue';
  import storefrontHeader from '../../components/allStores/storefrontHeader';
  import { mapActions, mapGetters, mapMutations } from 'vuex';
  import normaliseMetaTags from '@/utils/normaliseMetaTags';

  export default {
    name: 'AllStoresPage',
    components: {
      leftSidebarMenu,
      Stores,
      allStoreItems,
      storefrontHeader,
    },
    data() {
      return {
        isFiltersShow: false,
        metadata: {},
      };
    },
    computed: {
      ...mapGetters({
        allStore: 'allStoreCategories/allStore',
        showStoresAll: 'allStoreCategories/showStoresAll',
        loading: 'allStoreCategories/loading',
        head: 'allStoreCategories/head',
      }),
    },
    watch: {
      $route: {
        immediate: true,
        handler(path) {
          if (Object.keys(path.query).length === 0) {
            this.getAllStore();
          }
        },
      },
      head: function (head) {
        const metadata = normaliseMetaTags(head);
        delete metadata.title;
        this.metadata = metadata;
      },
    },
    created() {
      this.init();
      this.$stats.send('page_opened', {
        page_type: 'all-store',
      });
    },
    beforeDestroy() {
      this.setSort(null);
    },
    methods: {
      ...mapMutations({
        setSort: 'allStoreCategories/setSort',
      }),
      ...mapActions({
        getAllStore: 'allStoreCategories/getAllStore',
      }),
      async init() {
        await this.getAllStore({
          ...this.$route.query,
        });
      },
      showFilters() {
        this.isFiltersShow = true;
      },
      hideFilters() {
        this.isFiltersShow = false;
      },
    },
  };
</script>
<style lang="scss" scoped>
  .wrap {
    display: flex;

    .show-filters {
      left: 0px;
      transition: all;
      transition-duration: 0.6s;
    }
  }
  .storesWrap {
    width: 100%;

    @media screen and (min-width: 1200px) {
      width: calc(100% - 390px);
    }
  }
  .spinner-border {
    position: absolute;
    top: calc(50% - 23px);
    left: calc(50% - 23px);
  }
</style>
